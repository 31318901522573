import * as React from "react";
import Layout from "../components/templates/Layout/Layout";
import Section from "../components/templates/Section/Section";

const PrivacyPolicy: React.FC = () => {
    return (
        <Layout title={"Polityka prywatności"} secondary>
            <Section size={"medium"}>
                <ul>
                    <li>OK</li>
                </ul>
            </Section>
        </Layout>
    )
};

export default PrivacyPolicy;